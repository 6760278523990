import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export const Blog1 = require('../assets/images/blogs/Picture1.webp')
export const Blog2 = require('../assets/images/blogs/Picture2.webp')
export const Blog3 = require('../assets/images/blogs/Picture3.webp')
export const Blog4 = require('../assets/images/blogs/Picture4.webp')
export const Blog5 = require('../assets/images/blogs/Picture5.webp')
export const Blog6 = require('../assets/images/blogs/Picture6.webp')
export const Blog7 = require('../assets/images/blogs/Picture7.webp')
export const Blog8 = require('../assets/images/blogs/Picture8.webp')
export const Blog9 = require('../assets/images/blogs/Picture9.webp')
export const Blog10 = require('../assets/images/blogs/Picture10.webp')
export const Blog11 = require('../assets/images/blogs/Picture11.webp')
export const Blog12 = require('../assets/images/blogs/Picture12.webp')
export const Blog13 = require('../assets/images/blogs/Picture13.webp')
export const Blog14 = require('../assets/images/blogs/Picture14.webp')
export const Blog15 = require('../assets/images/blogs/Picture15.webp')
export const Blog16 = require('../assets/images/blogs/Picture16.webp')
export const Blog17 = require('../assets/images/blogs/Picture17.webp')
export const Blog18 = require('../assets/images/blogs/Picture18.webp')
export const Blog19 = require('../assets/images/blogs/Picture19.webp')
export const Blog20 = require('../assets/images/blogs/Picture20.webp')
export const Blog21 = require('../assets/images/blogs/Picture21.webp')
export const Blog22 = require('../assets/images/blogs/Picture22.webp')
export const Blog23 = require('../assets/images/blogs/Picture23.webp')
export const Blog24 = require('../assets/images/blogs/Picture24.webp')
export const Blog25 = require('../assets/images/blogs/Picture25.webp')
export const Blog26 = require('../assets/images/blogs/Picture26.webp')
export const Blog27 = require('../assets/images/blogs/Picture27.webp')
export const Blog25a = require('../assets/images/blogs/Blog25.webp')
export const Blog26a = require('../assets/images/blogs/Blog26.webp')
export const Blog27a = require('../assets/images/blogs/Blog27.webp')
export const Blog28 = require('../assets/images/blogs/Blog28.webp')
export const Blog29 = require('../assets/images/blogs/Blog29.webp')
export const Blog30 = require('../assets/images/blogs/Blog30.webp')
export const Blog31 = require('../assets/images/blogs/Blog31.webp')
export const Blog32 = require('../assets/images/blogs/Blog32.webp')
export const Blog33 = require('../assets/images/blogs/Blog33.webp')
export const Blog34 = require('../assets/images/blogs/Blog34.webp')
export const Blog35 = require('../assets/images/blogs/Blog35.webp')
export const Blog36 = require('../assets/images/blogs/Blog36.webp')
export const Blog37 = require('../assets/images/blogs/Blog37.webp')
export const Blog38 = require('../assets/images/blogs/Blog38.webp')
export const Blog39 = require('../assets/images/blogs/Blog39.webp')
export const Blog40 = require('../assets/images/blogs/Blog40.webp')
export const Blog41 = require('../assets/images/blogs/Blog41.webp')
export const Blog42 = require('../assets/images/blogs/Blog42.webp')
export const Blog43 = require('../assets/images/blogs/Blog43.webp')
export const Blog44 = require('../assets/images/blogs/Food Delivery Near Me - 5 Strategies for Choosing the Best Platform for Your Restaurant.webp')
export const Blog45 = require('../assets/images/blogs/Take Control of Your Revenue with Dispute Management.webp')
export const Blog46 = require("../assets/images/blogs/Increase ROI with Voosh.ai's Promotion Manager for GrubHub.webp")
export const Blog47 = require("../assets/images/blogs/High Wages are a daunting challenge that hinders growth and expansion for restaurants.webp")
export const Blog48 = require("../assets/images/blogs/Top 5 Ways.webp")
export const Blog49 = require("../assets/images/blogs/Maximize Your Revenue on DoorDash, Proven Strategies for Restaurants.webp")
export const Blog50 = require("../assets/images/blogs/Effective Uber Eats Strategies.webp")
export const Blog51 = require("../assets/images/blogs/Blog51.webp")
export const Blog52 = require("../assets/images/blogs/Boost Your Food Delivery Business with Automated Intelligence Solutions.webp")
export const Blog53 = require("../assets/images/blogs/Efficiently Manage Disputes on Uber Eats.webp")
export const Blog54 = require("../assets/images/blogs/Drive Exceptional Campaign Results on DoorDash with Voosh.ai's Promotion Manager.webp")
export const Blog55 = require("../assets/images/blogs/Enhance Your Restaurant's Reputation on DoorDash with Voosh.ai's Reviews & Ratings Management.webp")
export const Blog56 = require("../assets/images/blogs/Blog56.webp")
export const Blog57 = require("../assets/images/blogs/Blog57.webp")
export const Blog58 = require("../assets/images/blogs/Blog58.webp")
export const Blog59 = require("../assets/images/blogs/Blog59.webp")
export const Blog60 = require("../assets/images/blogs/Blog60.webp")
export const Blog61 = require("../assets/images/blogs/7 Ways to Enhance Customer Service on DoorDash.webp")
export const Blog62 = require("../assets/images/blogs/Effortlessly Manage Your Uber Eats Promotions.webp")
export const Blog63 = require("../assets/images/blogs/How to Optimize Your Restaurant Operations - Proven Techniques.webp")
export const Blog64 = require("../assets/images/blogs/Blog64.webp")
export const Blog65 = require("../assets/images/blogs/Maximize Revenue and Minimize Downtime on DoorDash with Voosh.ai.webp")
export const Blog66 = require("../assets/images/blogs/Gain Valuable Insights to Boost Your DoorDash Performance with Voosh.ai's Analytics & Insights.webp")
export const Blog67 = require("../assets/images/blogs/Improve Your GrubHub Reputation with Voosh.ai's Reviews & Ratings Management.webp")
export const Blog68 = require("../assets/images/blogs/Blog68.webp")
export const Blog69 = require("../assets/images/blogs/Improve Your Restaurant's Efficiency with Voosh.webp")
export const Blog70 = require("../assets/images/blogs/Unlock Valuable Insights for Your Restaurant's Growth on DoorDash with Voosh.webp")
export const Blog71 = require("../assets/images/blogs/Drive Success on Uber Eats with Voosh.ai's Dispute Manager.webp")
export const Blog72 = require("../assets/images/blogs/Streamline Your Financial Processes on DoorDash with Voosh.ai's Finance & Reconciliation.webp")
export const Blog73 = require("../assets/images/blogs/Maximize Revenue on GrubHub with Voosh.ai's Downtime Controller.webp")
export const Blog74 = require("../assets/images/blogs/Increase Your DoorDash Sales with Voosh.ai's Promotion Manager.webp")
export const Blog75 = require("../assets/images/blogs/Increase Sales and ROI with AI-Driven Promotion Manager.webp")
export const Blog76 = require("../assets/images/blogs/Drive Success with Voosh.ai and GrubHub.webp")
export const Blog77 = require("../assets/images/blogs/Eliminate Downtime on DoorDash with Voosh.webp")
export const Blog78 = require("../assets/images/blogs/Dominate the Food Delivery Market with Voosh's Solutions for Restaurants.webp")
export const Blog79 = require("../assets/images/blogs/DoorDash blog79.webp")
export const Blog80 = require("../assets/images/blogs/UberEats blog80.webp")
export const Blog81 = require("../assets/images/blogs/The Benefits of Account Reconciliation Automation for Restaurants.webp")
export const Blog82 = require("../assets/images/blogs/A Step-by-Step Guide to Disputing Order Errors on DoorDash and UberEats.webp")
export const Blog83 = require("../assets/images/blogs/How to Improve Your Restaurant's Online Reputation with Review Management.webp")
export const Blog84 = require("../assets/images/blogs/Maximize Your DoorDash Performance with Voosh.webp")
export const Blog85 = require("../assets/images/blogs/Financial Reconciliation for Restaurants Best Practices and Tools.webp")
export const Blog86 = require("../assets/images/blogs/Streamline Restaurant Management with Voosh.ai's Solutions.webp")
export const Blog87 = require("../assets/images/blogs/The Role of Automation in Resolving Disputes and Chargebacks for Restaurants.webp")
export const Blog88 = require("../assets/images/blogs/Improve Your DoorDash Reviews with Voosh.webp")
export const Blog89 = require("../assets/images/blogs/Blog89.webp")
export const Blog90 = require("../assets/images/blogs/ERROR.webp")
export const Blog91 = require("../assets/images/blogs/SkipTheDishes Canada.webp")
export const Blog92 = require("../assets/images/blogs/Enhance Customer Experience with Voosh.ai's Downtime Controller.webp")
export const Blog93 = require("../assets/images/blogs/Manage Your Online Reputation Effectively with Voosh Reviews & Ratings.webp")
export const Blog94 = require("../assets/images/blogs/Voosh Promotions.webp")
export const Blog95 = require("../assets/images/blogs/Increase Your Doordash Revenue with Voosh.webp")
export const Blog96 = require("../assets/images/blogs/Maximize Profits for Fast Food Restaurants with Voosh.webp")
export const Blog97 = require("../assets/images/blogs/Maximize Your Restaurant's Visibility with GrubHub, DoorDash, and Uber Eats.webp")
export const Blog98 = require("../assets/images/blogs/Improving Invoice Dispute Management for Restaurants Tips and Best Practices.webp")
export const Blog99 = require("../assets/images/blogs/Simplify Financial Reconciliation and Gain Immediate Insights with Voosh ai s Finance & Reconciliation.webp")
export const Blog100 = require("../assets/images/blogs/Dominate the Food Delivery Industry with GrubHub, DoorDash, and Uber Eats.webp")
export const Blog101 = require("../assets/images/blogs/Achieve Zero Downtime and Maximize Revenue with Voosh Downtime Controller.webp")
export const Blog102 = require("../assets/images/blogs/Boost Your Restaurants Business on DoorDash with Voosh ai.webp")
export const Blog103 = require("../assets/images/blogs/Maximize Your Promotions Across Multiple Platforms with Voosh.ai's Promotion Manager.webp")
export const Blog104 = require("../assets/images/blogs/Increase Your DoorDash Sales with Voosh.ai.webp")
export const Blog105 = require("../assets/images/blogs/Streamline Your DoorDash Finances with Voosh.webp")
export const Blog106 = require("../assets/images/blogs/Voosh Finance Streamlining Financial Reconciliation for Restaurants on Third Party Marketplaces.webp")
export const Blog107 = require("../assets/images/blogs/Enhance Brand Reputation Effectively Manage Reviews on Third Party Marketplaces.webp")
export const Blog108 = require("../assets/images/blogs/Boost Your Restaurant's Sales with GrubHub, DoorDash, and Uber Eats.webp")
export const Blog109 = require("../assets/images/blogs/7 Strategies for Effective Promotions on Food Delivery Platforms.webp")
export const Blog110 = require("../assets/images/blogs/Top Marketplace Management Services for Restaurants.webp")
export const Blog111 = require("../assets/images/blogs/How Restaurants Can Resolve Error Charges on Food Delivery Apps.webp")
export const Blog111x = require("../assets/images/blogs/Blog111:1.webp")
export const Blog111y = require("../assets/images/blogs/Blog111:2.webp")
export const Blog111z = require("../assets/images/blogs/Blog111:3.webp")
export const Blog112 = require("../assets/images/blogs/How to Increase Online Food Orders for Your Restaurant.webp")
export const PR1 = require('../assets/images/blogs/PR1.png')
export const PR2 = require('../assets/images/blogs/PR2.png')
export const PR3 = require('../assets/images/blogs/PR3.png')
export const PR4 = require('../assets/images/blogs/PR4.webp')
export const PR5 = require('../assets/images/blogs/PR5.webp')
export const Blog998 = require('../assets/images/blogs/article/Voosh Dispute Manager.webp')


// Blog1,Blog2,Blog3,Blog4,Blog5,Blog6,Blog7,Blog8,Blog9,Blog10,Blog11,Blog12,Blog13,Blog14,Blog15,Blog16,Blog17,Blog18,Blog19,Blog20,Blog21,Blog22,Blog23,Blog24,Blog25,Blog26,Blog27,Blog25a,Blog26a,Blog27a,Blog28,Blog29,Blog30,Blog31,Blog32,Blog33,Blog34,Blog35,Blog36,Blog37,Blog38,Blog39,PR1,PR2,PR3,PR4,PR5


const BlogDataJson = [
    {
        "id" : 1,
        "img" : Blog1,
        "title" : "A Comprehensive Guide to Marketplace Management for Restaurants.",
        "publishedDate" : "15th Nov, 2023",
        "url" : "/blogs/a-comprehensive-guide-to-marketplace-management-for-restaurants",
        "desc" : "Streamline operations, improve customer satisfaction, and maximize profits with Voosh. Start your 30-day free trial today!"
    },
    {
        "id" : 2,
        "img" : Blog2,
        "title" : "Mastering Dispute Management on DoorDash, Uber Eats, and GrubHub.",
        "publishedDate" : "21st Nov, 2023",
        "url" : "/blogs/mastering-dispute-management-on-doordash-ubereats-and-grubhub",
        "desc" : "Master dispute management on DoorDash, Uber Eats & GrubHub. Solve order issues, improve food quality & handle billing problems. Streamline disputes with Voosh's efficient solution. Start your free trial now!"
    },
    {
        "id" : 3,
        "img" : Blog3,
        "title" : "Streamlining Financial Reconciliation for Restaurants on 3rd Party Delivery Platforms.",
        "publishedDate" : "28th Nov, 2023",
        "url" : "/blogs/streamlining-financial-reconciliation-for-restaurants-using-third-party-delivery-platforms",
        "desc" : "Streamline financial reconciliation for restaurants on third-party delivery platforms. Overcome challenges and achieve success with Voosh's streamlined solution. Start your 30-day free trial today!"
    },
    {
        "id" : 4,
        "img" : Blog4,
        "title" : "Top 10 Tips for Managing Your Restaurant on Third-Party Delivery Platforms",
        "publishedDate" : "30th Nov, 2023",
        "url" : "/blogs/top-10-tips-for-managing-your-restaurant-on-third-party-delivery-platforms",
        "desc" : "Discover the top 10 tips for managing your restaurant on popular third-party delivery platforms like DoorDash, Uber Eats, and GrubHub. Start your free trial now!"
    },
    {
        "id" : 5,
        "img" : Blog5,
        "title" : "How to Effectively Manage Promotions for Your Restaurant on Third-Party Platforms",
        "publishedDate" : "05th Dec, 2023",
        "url" : "/blogs/how-to-effectively-manage-promotions-for-your-restaurant-on-third-party-platforms",
        "desc" : "Unlock Your Restaurant's Potential with Voosh! Effectively manage promotions on third-party platforms like DoorDash, Uber Eats, and GrubHub. Streamline processes, save time, reduce costs, and maximize profits."
    },
    {
        "id" : 6,
        "img" : Blog6,
        "title" : "Maximize Your Restaurant's Growth on Third-Party Delivery Platforms: Tips & Tricks",
        "publishedDate" : "06th Dec, 2023",
        "url" : "/blogs/maximize-your-restaurants-growth-on-third-party-delivery-platforms-doordash-ubereats-grubhub-tips-and-tricks",
        "desc" : "Maximize your restaurant's growth on third-party delivery platforms with tips & tricks. Learn how to choose the right platforms, streamline operations with Voosh, optimize your menu, and leverage data-driven insights."
    },
    {
        "id" : 7,
        "img" : Blog7,
        "title" : "Financial Reconciliation for Restaurants: Simplifying Processes and Boosting Profits",
        "publishedDate" : "08th Dec, 2023",
        "url" : "/blogs/financial-reconciliation-for-restaurants-simplifying-processes-and-boosting-profits",
        "desc" : "Simplify restaurant financial reconciliation and boost profits with Voosh. Streamline processes, save time, and make data-driven decisions. Discover the power of Voosh's DoorDash, UberEats, Grubhub and EZCater integration now!"
    },
    {
        "id" : 8,
        "img" : Blog8,
        "title" : "Voosh Welcomes EZCater Integration: Making Financial Management a Piece of Cake!",
        "publishedDate" : "12th Dec, 2023",
        "url" : "/blogs/voosh-welcomes-ezcater-integration-making-financial-management-a-piece-of-cake",
        "desc" : "Voosh.ai improves financial management for restaurants by introducing a new integration with EZCater, providing a comprehensive dashboard that simplifies financial reconciliation and enables in-depth analysis of data from third-party marketplaces."
    },
    {
        "id" : 9,
        "img" : Blog9,
        "title" : "Voosh's Dispute Manager Meets DoorDash Drive",
        "publishedDate" : "12th Dec, 2023",
        "url" : "/blogs/voosh-dispute-manager-meets-doordash-drive",
        "desc" : "Voosh is transforming the way restaurants handle disputes by integrating with DoorDash Drive. Voosh's upgraded Dispute Manager automates dispute resolution on various delivery platforms, such as DoorDash Drive, UberEats, DoorDash, and GrubHub."
    },
    {
        "id" : 10,
        "img" : Blog10,
        "title" : "Voosh's Review Manager Now Even Better: Hello Google and Yelp Integration!",
        "publishedDate" : "12th Dec, 2023",
        "url" : "/blogs/voosh-review-manager-now-even-better-hello-google-and-yelp-integration",
        "desc" : "Voosh's Enhanced Review Manager: Featuring Google and Yelp Integration - An innovative solution for restaurants to easily handle customer reviews and enhance their online reputation on DoorDash, UberEats, GrubHub, Google, and Yelp."
    },
    {
        "id" : 11,
        "img" : PR1,
        "title" : "Voosh.ai Introduces EZCater Integration, Elevating Financial Management for Restaurants",
        "publishedDate" : "12th Dec, 2023",
        "url" : "/blogs/voosh-introduces-ezcater-integration-elevating-financial-management-for-restaurants",
        "desc" : "Voosh.ai Enhances Financial Management for Restaurants with New EZCater Integration, Offering Comprehensive Dashboard for Easy Financial Reconciliation and Analysis of Third-Party Marketplace Data."
    },
    {
        "id" : 12,
        "img" : PR2,
        "title" : "Voosh Enhances Dispute Manager with Groundbreaking DoorDash Drive Integration",
        "publishedDate" : "12th Dec, 2023",
        "url" : "/blogs/voosh-enhances-dispute-manager-with-groundbreaking-doordash-drive-integration",
        "desc" : "Voosh Revolutionizes Restaurant Dispute Management with DoorDash Drive Integration: Voosh's enhanced Dispute Manager offers seamless resolution across multiple delivery platforms, including DoorDash Drive, UberEats, DoorDash, and GrubHub."
    },
    {
        "id" : 13,
        "img" : PR3,
        "title" : "Voosh Announces Major Upgrade to Review Manager Feature: Now Includes Google and Yelp Integration",
        "publishedDate" : "12th Dec, 2023",
        "url" : "/blogs/voosh-announces-major-upgrade-to-review-manager-feature-now-includes-google-and-yelp-integration",
        "desc" : "Voosh's Enhanced Review Manager: Now with Google and Yelp Integration - A game-changing tool for restaurants to effortlessly manage customer reviews and boost their online reputation in DoorDash, UberEats, GrubHub, Google and Yelp."
    },
    {
        "id" : 14,
        "img" : Blog11,
        "title" : "Mastering Reviews and Ratings: Boost Your Restaurant's Reputation on DoorDash, UberEats, GrubHub, Google, and Yelp",
        "publishedDate" : "28th Dec, 2023",
        "url" : "/blogs/mastering-reviews-and-ratings-boost-your-restaurants-reputation-on-doordash-ubereats-and-grubhub",
        "desc" : "Master your restaurant's reputation on DoorDash, UberEats, GrubHub, Google and Yelp with Voosh. Enhance customer experience, boost ratings, and increase profits. Click now to take control of your online presence."
    },
    {
        "id" : 15,
        "img" : Blog12,
        "title" : "Ghost Kitchens vs Multi-Unit Restaurants: Maximizing Profits with Voosh's Restaurant Marketplace Intelligence Platform",
        "publishedDate" : "03th Jan, 2024",
        "url" : "/blogs/ghost-kitchens-vs-multi-unit-restaurants-maximizing-profits-with-vooshs-restaurant-marketplace-intelligence-platform",
        "desc" : "Ghost Kitchens vs Multi-Unit Restaurants: Maximize profits with Voosh's Restaurant Marketplace Intelligence Platform. Streamline operations, manage online presence, and boost profits on third-party delivery platforms"
    },
    {
        "id" : 16,
        "img" : Blog13,
        "title" : "Maximizing Profits on DoorDash: Tips and Tricks for Restaurant Operators",
        "publishedDate" : "04th Jan, 2024",
        "url" : "/blogs/maximizing-profits-on-doordash-tips-and-tricks-for-restaurant-operators",
        "desc" : "Maximize profits on DoorDash with Voosh's comprehensive solutions. Optimize your menu, improve order efficiency, enhance customer experience, manage finances, promote your restaurant, and analyze performance. Try Voosh for free!"
    },
    {
        "id" : 17,
        "img" : Blog14,
        "title" : "GrubHub Growth Hacks: Boost Your Restaurant's Performance on the Platform",
        "publishedDate" : "08th Jan, 2024",
        "url" : "/blogs/grubhub-growth-hacks-boost-your-restaurants-performance-on-the-platform",
        "desc" : "Boost your restaurant's performance on the GrubHub platform with these growth hacks. Learn how to optimize your profile, maximize visibility with promotions, streamline operations with Voosh integration, enhance your online reputation with reviews and ratings..."
    },
    {
        "id" : 18,
        "img" : Blog15,
        "title" : "The Secret to UberEats Success: How Restaurants Can Optimize Operations",
        "publishedDate" : "09th Jan, 2024",
        "url" : "/blogs/the-secret-to-ubereats-success-how-restaurants-can-optimize-operations",
        "desc" : "The secret to Uber Eats success: Optimize restaurant operations & maximize profits. Discover how Voosh helps restaurants manage and grow on third-party delivery platforms. Try Voosh's 30-day free trial now!"
    },
    {
        "id" : 19,
        "img" : Blog16,
        "title" : "How Voosh.ai Can Help Restaurants Reduce Error Charges and Win Disputes",
        "publishedDate" : "10th Jan, 2024",
        "url" : "/blogs/how-voosh-can-help-restaurants-reduce-error-charges-and-win-disputes",
        "desc" : "Voosh.ai helps restaurants reduce error charges and win disputes. Streamline third-party delivery management with dispute resolution and charge reduction tools. Try voosh.ai to optimize your restaurant's success."
    },
    {
        "id" : 20,
        "img" : Blog17,
        "title" : "Master the Art of Dispute Resolution for Third-Party Delivery Services: Tips and Tricks for Doordash, Grubhub, and Uber Eats",
        "publishedDate" : "11th Jan, 2024",
        "url" : "/blogs/master-the-art-of-dispute-resolution-for-third-party-delivery-services-tips-and-tricks-for-doordash-grubhub-and-ubereats",
        "desc" : "Master the art of dispute resolution for third-party delivery services with these tips and tricks. Learn how to manage disputes on platforms like Doordash, Grubhub, and Uber Eats."
    },
    {
        "id" : 21,
        "img" : Blog18,
        "title" : "Top 5 Reasons to Choose Voosh for Your Restaurant's Third-Party Marketplace Management",
        "publishedDate" : "18th Jan, 2024",
        "url" : "/blogs/top-5-reasons-to-choose-voosh-for-your-restaurants-third-party-marketplace-management",
        "desc" : "Choose Voosh for your restaurant's third-party marketplace management and experience growth, efficiency, and success. Book a live demo today and unlock your restaurant's potential."
    },
    {
        "id" : 22,
        "img" : Blog19,
        "title" : "Optimizing Reviews and Ratings for Your Restaurant on Delivery Platforms",
        "publishedDate" : "19th Jan, 2024",
        "url" : "/blogs/optimizing-reviews-and-ratings-for-your-restaurant-on-delivery-platforms",
        "desc" : "Optimize your restaurant's reviews and ratings on delivery platforms with Voosh.ai's Review Manager. Streamline the process, boost customer trust, and drive business growth. Try our 30-day free trial."
    },
    {
        "id" : 23,
        "img" : Blog20,
        "title" : "The Role of Quick Service Restaurants in the Era of Third-Party Delivery Platforms",
        "publishedDate" : "19th Jan, 2024",
        "url" : "/blogs/the-role-of-quick-service-restaurants-in-the-era-of-third-party-delivery-platforms",
        "desc" : "The Role of Quick Service Restaurants in the Era of Third-Party Delivery Platforms. Discover how Voosh.ai helps restaurants thrive. Click here to learn more."
    },
    {
        "id" : 24,
        "img" : Blog21,
        "title" : "Streamlining Finance and Reconciliation for Restaurants on Delivery Platforms",
        "publishedDate" : "20th Jan, 2024",
        "url" : "/blogs/streamlining-finance-and-reconciliation-for-restaurants-on-delivery-platforms",
        "desc" : "Streamline your restaurant's finance and reconciliation on delivery platforms with Voosh.ai. Save time, reduce costs, and maximize profits. Start your 30-day free trial now!"
    },
    {
        "id" : 25,
        "img" : Blog22,
        "title" : "10 Examples of AI-Powered Customer Support in Food Delivery",
        "publishedDate" : "29th Jan, 2024",
        "url" : "/blogs/10-examples-of-ai-powered-customer-support-in-food-delivery",
        "desc" : "Enhance food delivery customer support with AI-powered tools. Get personalized recommendations, seamless ordering, and optimized operations. Explore Voosh's features and sign up for a 30-day free trial."
    },
    {
        "id" : 26,
        "img" : Blog23,
        "title" : "5 Ways to Maximize Your Pizza Delivery Profits",
        "publishedDate" : "29th Jan, 2024",
        "url" : "/blogs/5-ways-to-maximize-your-pizza-delivery-profits",
        "desc" : "Maximize your pizza delivery profits with these 5 strategies. Streamline operations, optimize menus, and improve customer experiences with Voosh.ai. Try our 30-day free trial now!"
    },
    {
        "id" : 27,
        "img" : Blog24,
        "title" : "DoorDash vs. UberEats vs. GrubHub: Which Platform is Best for Your Restaurant?",
        "publishedDate" : "29th Jan, 2024",
        "url" : "/blogs/doordash-vs-ubereats-vs-grubhub-which-platform-is-best-for-your-restaurant",
        "desc" : "DoorDash vs. UberEats vs. GrubHub: Find the Best Platform for Your Restaurant! Discover the differences and benefits of each food delivery service to maximize your restaurant's success. Click now."
    },
    {
        "id" : 28,
        "img" : Blog25,
        "title" : "Why Yelp Matters: Leveraging Online Reviews to Boost Your Restaurant's Reputation",
        "publishedDate" : "01st Feb, 2024",
        "url" : "/blogs/why-yelp-matters-leveraging-online-reviews-to-boost-your-restaurants-reputation",
        "desc" : "Unlock restaurant success with Yelp! Learn how to leverage online reviews & boost your restaurant's reputation. Visit Voosh.ai to manage and grow your business on third-party platforms."
    },
    {
        "id" : 29,
        "img" : Blog26,
        "title" : "Top 5 Voosh Features Every Restaurant Needs to Succeed",
        "publishedDate" : "02nd Feb, 2024",
        "url" : "/blogs/top-5-voosh-features-every-restaurant-needs-to-succeed",
        "desc" : "Unlock Voosh's Potential: Boost your restaurant's success with the top 5 Voosh features. Streamline operations, manage disputes, enhance reviews, optimize finances, and maximize promotions. Try Voosh."
    },
    {
        "id" : 30,
        "img" : Blog27,
        "title" : "Increasing Dispute Win Rates and Reducing Error Charges for Restaurants with Voosh.ai",
        "publishedDate" : "03rd Feb, 2024",
        "url" : "/blogs/increasing-dispute-win-rates-and-reducing-error-charges-for-restaurants-with-voosh",
        "desc" : "Increase dispute win rates & reduce error charges for restaurants with Voosh.ai. Optimize profits & grow your business. Explore now!"
    },
    {
        "id" : 31,
        "img" : Blog25a,
        "title" : "Optimizing Your Restaurant's Performance on Uber Eats: Top Tips and Strategies",
        "publishedDate" : "08th Feb, 2024",
        "url" : "/blogs/optimizing-your-restaurant-performance-on-uber-eats-top-tips-and-strategies",
        "desc" : "Optimize your restaurant's performance on Uber Eats with top tips and strategies. Maximize revenue and stay competitive in the rapidly growing food delivery market. Try Voosh for free and unlock your restaurant's"
    },
    {
        "id" : 32,
        "img" : Blog26a,
        "title" : "Boost Your Chinese Cuisine Orders with These Tips",
        "publishedDate" : "09th Feb, 2024",
        "url" : "/blogs/boost-your-chinese-cuisine-orders-with-these-tips-and-maximize-profits",
        "desc" : "Boost your Chinese cuisine orders with these tips. Maximize profits and stay competitive in the restaurant industry. Try Voosh's 30-day free trial now!"
    },
    {
        "id" : 33,
        "img" : Blog27a,
        "title" : "Leveraging Voosh.ai for Restaurant Success on DoorDash, Uber Eats, and GrubHub",
        "publishedDate" : "10th Feb, 2024",
        "url" : "/blogs/leveraging-voosh-ai-for-restaurant-success-on-door-dash-uber-eats-and-grub-hub",
        "desc" : "Leverage Voosh.ai to succeed on DoorDash, Uber Eats, and GrubHub. Streamline operations, maximize profits, and boost your restaurant's success. Sign up today!"
    },
    {
        "id" : 34,
        "img" : Blog28,
        "title" : "Why Voosh is a Game-Changer for Restaurants Navigating Third-Party Delivery Platforms",
        "publishedDate" : "15th Feb, 2024",
        "url" : "/blogs/why-voosh-is-a-game-changer-for-restaurants-navigating-third-party-delivery-platforms",
        "desc" : "Discover how Voosh is revolutionizing restaurant management on third-party delivery platforms. Streamline operations, maximize profits, and enhance customer experience with Voosh.ai."
    },
    {
        "id" : 35,
        "img" : Blog29,
        "title" : "A Comprehensive Guide to Financial Statement Reconciliation for Restaurants using Voosh.ai",
        "publishedDate" : "17th Feb, 2024",
        "url" : "/blogs/a-comprehensive-guide-to-financial-statement-reconciliation-for-restaurants-using-voosh",
        "desc" : "A comprehensive guide to financial statement reconciliation for restaurants using Voosh.ai. Ensure smooth operations, compliance, and growth with our efficient solution. Click to learn more!"
    },
    {
        "id" : 36,
        "img" : Blog30,
        "title" : "Maximize Restaurant Profits with Voosh: A Comprehensive Solution for Third-Party Delivery Management",
        "publishedDate" : "18th Feb, 2024",
        "url" : "/blogs/maximize-restaurant-profits-with-voosh-a-comprehensive-solution-for-third-party-delivery-management",
        "desc" : "Streamline third-party delivery management & boost business growth. Get comprehensive features & insights to succeed on platforms like DoorDash, Uber Eats, ezCater, Google, Yelp, DoorDash Drive and GrubHub."
    },
    {
        "id" : 37,
        "img" : Blog31,
        "title" : "Unlocking Papa John's Success: 6 Lessons for Growing Your Restaurant",
        "publishedDate" : "22nd Feb, 2024",
        "url" : "/blogs/unlocking-papa-johns-success-6-lessons-for-growing-your-restaurant",
        "desc" : "Unlock the secrets of Papa John's success with 6 lessons for growing your restaurant. Learn how to prioritize quality ingredients, invest in technology, focus on customer experience, build a strong brand, expand strategically."
    },
    {
        "id" : 38,
        "img" : Blog32,
        "title" : "Fox News: Top 4 Restaurant Industry Trends to Watch",
        "publishedDate" : "23rd Feb, 2024",
        "url" : "/blogs/fox-news-top-4-restaurant-industry-trends-to-watch",
        "desc" : "Discover the top 4 restaurant industry trends to watch, as reported by Fox News. Stay competitive, meet customer demands, and enhance your dining experience. Click to learn more!"
    },
    {
        "id" : 39,
        "img" : Blog33,
        "title" : "Stay Informed with Breaking News in the Restaurant Industry",
        "publishedDate" : "24th Feb, 2024",
        "url" : "/blogs/stay-informed-with-breaking-news-in-the-restaurant-industry",
        "desc" : "Stay informed with the latest breaking news in the restaurant industry. Gain valuable insights, stay ahead of the competition, and make informed decisions for your business. Click here to dive into the future of your restaurant."
    },
    {
        "id" : 40,
        "img" : Blog34,
        "title" : "Jack in the Box's Success: Leveraging Third-Party Delivery Platforms",
        "publishedDate" : "25th Feb, 2024",
        "url" : "/blogs/jack-in-the-boxs-success-leveraging-third-party-delivery-platforms",
        "desc" : "Jack in the Box's success story of leveraging third-party delivery platforms & how it can help your restaurant optimize its presence & boost revenue. Sign up for a 30-day free trial or book a live demo."
    },
    {
        "id" : 41,
        "img" : Blog35,
        "title" : "How Starbucks Excels in Delivery Platform Management",
        "publishedDate" : "26th Feb, 2024",
        "url" : "/blogs/how-starbucks-excels-in-delivery-platform-management",
        "desc" : "Discover how Starbucks excels in delivery platform management. Learn their strategies and practices for success. Click here for more!"
    },
    {
        "id" : 42,
        "img" : PR4,
        "title" : "Voosh Launches Third Party Marketplace Downtime Controller",
        "publishedDate" : "28th Feb, 2024",
        "url" : "/blogs/voosh-launches-third-party-marketplace-downtime-controller",
        "desc" : "Downtime Controller monitors your store's status across DoorDash, Uber Eats, and more, ensuring you're always open for business when you need to be. With instant alerts, comprehensive analytics, and the ability to turn stores on or off with a click."
    },
    {
        "id" : 43,
        "img" : PR5,
        "title" : "Voosh Adds Promotions Creation Tool to Its Third-Party Marketplace Promotions Manager",
        "publishedDate" : "28th Feb, 2024",
        "url" : "/blogs/voosh-adds-promotions-creation-tool-to-its-third-party-marketplace-promotions-manager",
        "desc" : "Set up, manage, and track your restaurant promotions across various third party delivery platforms from a single dashboard. Get detailed insights on sales, ROI, customer engagement, and more."
    },
    {
        "id" : 44,
        "img" : Blog36,
        "title" : "How to Succeed on DoorDash: Essential Tips for Restaurants",
        "publishedDate" : "05th March, 2024",
        "url" : "/blogs/how-to-succeed-on-doordash-essential-tips-for-restaurants",
        "desc" : "How to Succeed on DoorDash: Essential Tips for Restaurants - Enhance visibility, drive sales, and foster growth on DoorDash. Click here to learn more!"
    },
    {
        "id" : 45,
        "img" : Blog37,
        "title" : "Unlocking the Power of Google for Your Business",
        "publishedDate" : "08th March, 2024",
        "url" : "/blogs/unlocking-the-power-of-google-for-your-business",
        "desc" : "Unlock the power of Google for your business. Learn strategies to attract customers, increase visibility, and drive growth. Discover the secrets to online success with Google."
    },
    {
        "id" : 46,
        "img" : Blog38,
        "title" : "Boost Your DoorDash Performance with Voosh.ai",
        "publishedDate" : "09th March, 2024",
        "url" : "/blogs/boost-your-door-dash-performance-with-voosh-ai",
        "desc" : "Boost your DoorDash performance with Voosh.ai. Streamline your operations, manage disputes, track promotions, and enhance your reputation. Take your restaurant's business to the next level."
    },
    {
        "id" : 47,
        "img" : Blog39,
        "title" : "Maximize Your Restaurant's Performance on GrubHub",
        "publishedDate" : "13th March, 2024",
        "url" : "/blogs/maximize-your-restaurants-performance-on-grubhub",
        "desc" : "Maximize your restaurant's performance on GrubHub with Voosh.ai. Optimize your profile, manage customer reviews, and boost revenue. Discover how restaurants can thrive in the digital age of food delivery."
    },
    {
        "id" : 48,
        "img" : Blog40,
        "title" : "Unlock the Power of AI for Efficient Restaurant Management",
        "publishedDate" : "14th March, 2024",
        "url" : "/blogs/unlock-the-power-of-ai-for-efficient-restaurant-management",
        "desc" : "Unlock the power of AI to optimize your restaurant management. Enhance customer experience, streamline operations, and stay ahead in the competitive market. Discover the possibilities with Voosh.ai."
    },
    {
        "id" : 49,
        "img" : Blog41,
        "title" : "Maximize Your Profits with Voosh.ai's Promotions Management",
        "publishedDate" : "15th March, 2024",
        "url" : "/blogs/maximize-your-profits-with-voosh-ai-promotions-management",
        "desc" : "Maximize profits with Voosh.ai's Promotion Manager. Configure, Streamline and track promotions across multiple platforms. Sign up for a free trial today!"
    },
    {
        "id" : 50,
        "img" : Blog42,
        "title" : "Increasing Profitability with Profitable Promotions: A Success Story from a New York-based Culinary Chain",
        "publishedDate" : "16th March, 2024",
        "url" : "/blogs/increasing-profitability-with-profitable-promotions-a-success-story-from-a-new-york-based-culinary-chain",
        "desc" : "Increase profitability with profitable promotions! Learn from a New York-based culinary chain's success story and discover how to attract more customers, increase sales, and boost profitability. Don't miss out on replicating their strategy!"
    },
    {
        "id" : 51,
        "img" : Blog43,
        "title" : "Maximizing Profits on Uber Eats: Top Strategies for Restaurants",
        "publishedDate" : "25th March, 2024",
        "url" : "/blogs/maximizing-profits-on-uber-eats-top-strategies-for-restaurants",
        "desc" : "Maximize your Uber Eats profits with top restaurant strategies. Stand out, attract customers, and increase profitability. Learn more now!"
    },
    {
        "id" : 52,
        "img" : Blog44,
        "title" : "Food Delivery Near Me: 5 Strategies for Choosing the Best Platform for Your Restaurant",
        "publishedDate" : "26th March, 2024",
        "url" : "/blogs/food-delivery-near-me-5-strategies-for-choosing-the-best-platform-for-your-restaurant",
        "desc" : "Discover the ultimate solution for restaurant management on third-party delivery platforms. Voosh.ai empowers restaurants to take control of their operations and reclaim lost profits. Sign up for a free 30-day trial."
    },
    {
        "id" : 53,
        "img" : Blog45,
        "title" : "Take Control of Your Revenue with Dispute Management",
        "publishedDate" : "27th March, 2024",
        "url" : "/blogs/take-control-of-your-revenue-with-dispute-management",
        "desc" : "Take control of your restaurant revenue with Voosh.ai's dispute management. Streamline operations, maximize profits, and optimize success on third-party delivery platforms. Get started today!"
    },
    {
        "id" : 54,
        "img" : Blog46,
        "title" : "Increase ROI with Voosh.ai's Promotion Manager for GrubHub",
        "publishedDate" : "27th March, 2024",
        "url" : "/blogs/increase-roi-with-voosh-ai-promotion-manager-for-grubhub",
        "desc" : "Increase your ROI on GrubHub with Voosh.ai's Promotion Manager. Configure, manage, and track the effectiveness of your promotions from a centralized dashboard. Take control of your promotions and maximize your profits!"
    },
    {
        "id" : 55,
        "img" : Blog47,
        "title" : "🔥Wages have definitely doubled since 2008, says Shawn P. Walchef of Cali BBQ.",
        "publishedDate" : "04th April, 2024",
        "url" : "/blogs/wages-have-definitely-doubled-since-2008-says-shawn-p-walchef-of-cali-bbq",
        "desc" : "High Wages are a daunting challenge that hinders growth and expansion for restaurants. At Voosh, we've saved over 10,000 hours of human effort by automating dispute resolution, promotions, finance, and customer reviews for restaurants."
    },
    {
        "id" : 56,
        "img" : Blog48,
        "title" : "Top 5 Ways to Use DoorDash Promo Codes for Boosting Your Restaurant Sales",
        "publishedDate" : "04th April, 2024",
        "url" : "/blogs/top-5-ways-to-use-doordash-promo-codes-for-boosting-your-restaurant-sales",
        "desc" : "Boost your restaurant sales with DoorDash promo codes. Attract new customers and track the effectiveness of your promotions. Unlock the full potential of DoorDash promo codes now!"
    },
    {
        "id" : 57,
        "img" : Blog49,
        "title" : "Maximize Your Revenue on DoorDash: Proven Strategies for Restaurants",
        "publishedDate" : "05th April, 2024",
        "url" : "/blogs/maximize-your-revenue-on-doordash-proven-strategies-for-restaurants",
        "desc" : "Maximize your revenue on DoorDash with proven strategies for restaurants. Sign up for Voosh.ai's free trial and take control of your DoorDash presence to boost earnings."
    },
    {
        "id" : 58,
        "img" : Blog50,
        "title" : "Drive More Orders and Increase Revenue with Effective Uber Eats Strategies",
        "publishedDate" : "05th April, 2024",
        "url" : "/blogs/drive-more-orders-and-increase-revenue-with-effective-uber-eats-strategies",
        "desc" : "Drive more orders and boost revenue with effective Uber Eats strategies. Learn how to optimize your presence on food delivery platforms. Explore key elements of successful strategies and discover valuable insights to maximize profits."
    },
    {
        "id" : 59,
        "img" : Blog51,
        "title" : "John Oliver's Last Week Tonight reveals a shocking truth 🤯 behind the convenience of food delivery apps 🛵",
        "publishedDate" : "09th April, 2024",
        "url" : "/blogs/john-olivers-last-week-tonight-reveals-a-shocking-truth-behind-the-convenience-of-food-delivery-apps",
        "desc" : "John Oliver's Last Week Tonight reveals a shocking truth behind the convenience of food delivery apps. With soaring user dependency, these platforms impose hefty commissions and other fees..."
    },
    {
        "id" : 60,
        "img" : Blog52,
        "title" : "Boost Your Delivery Business with Automated Intelligence Solutions",
        "publishedDate" : "12th April, 2024",
        "url" : "/blogs/boost-your-delivery-business-with-automated-intelligence-solutions",
        "desc" : "Boost your delivery business with automated intelligence solutions. Streamline operations, improve efficiency, and achieve growth. Try Voosh.ai for free!"
    },
    {
        "id" : 61,
        "img" : Blog53,
        "title" : "Efficiently Manage Disputes on Uber Eats",
        "publishedDate" : "12th April, 2024",
        "url" : "/blogs/efficiently-manage-disputes-on-uber-eats",
        "desc" : "Efficiently manage disputes on Uber Eats with Voosh.ai. Protect your restaurant's reputation and maximize earnings. Sign up for a free 30-day trial now!"
    },
    {
        "id" : 62,
        "img" : Blog54,
        "title" : "Drive Exceptional Campaign Results on DoorDash with Voosh.ai's Promotion Manager",
        "publishedDate" : "13th April, 2024",
        "url" : "/blogs/drive-exceptional-campaign-results-on-doordash-with-voosh-ai-promotion-manager",
        "desc" : "Drive exceptional campaign results on DoorDash with Voosh.ai's Promotion Manager. Maximize profits and boost performance on third-party delivery platforms. Start your free 30-day trial now!"
    },
    {
        "id" : 63,
        "img" : Blog55,
        "title" : "Enhance Your Restaurant's Reputation on DoorDash with Voosh.ai's Reviews & Ratings Management",
        "publishedDate" : "14th April, 2024",
        "url" : "/blogs/enhance-your-restaurant-reputation-on-doordash-with-voosh-reviews-ratings-management",
        "desc" : "Enhance your restaurant's reputation on DoorDash with Voosh.ai's Reviews & Ratings Management. Boost customer trust, attract more diners, and take your restaurant to the next level. Discover the power"
    },
    {
        "id" : 64,
        "img" : Blog56,
        "title" : "🚨 Most brands spend 10-15% of sales on promotions without any clear return 🚨",
        "publishedDate" : "16th April, 2024",
        "url" : "/blogs/most-brands-spend-10-15-percent-of-sales-on-promotions-without-any-clear-return",
        "desc" : "Most brands spend 10-15% of sales on promotions without any clear return. With Voosh, gain actionable insights and manage all your promotions in one spot—effortlessly."
    },
    {
        "id" : 66,
        "img" : Blog57,
        "title" : "🎯 Is your promotional strategy really tailored to your market? 🎯",
        "publishedDate" : "16th April, 2024",
        "url" : "/blogs/is-your-promotional-strategy-really-tailored-to-your-market",
        "desc" : "With Voosh, customize your promotional strategies to fit the unique demands of each locale, ensuring every dollar spent is an investment towards measurable growth!"
    },
    {
        "id" : 67,
        "img" : Blog58,
        "title" : "🤯 Did you know that over $500 million is lost annually in disputed restaurant orders? 🤯",
        "publishedDate" : "17th April, 2024",
        "url" : "/blogs/did-you-know-that-over-$500-million-is-lost-annually-in-disputed-restaurant-orders",
        "desc" : "Voosh's Dispute Manager simplifies profit recovery with a single click, streamlining the process for swift results. This innovative tool eliminates complexities, enabling businesses to efficiently manage disputes and maximize revenue potential."
    },
    {
        "id" : 68,
        "img" : Blog59,
        "title" : "🎙️ Samuel, VP at TGI Fridays, asked at MURTEC: 'How quickly can you see returns with Voosh?' 🕒💰",
        "publishedDate" : "18th April, 2024",
        "url" : "/blogs/samuel-vp-at-tgi-fridays-asked-at-murtec-how-quickly-can-you-see-returns-with-voosh",
        "desc" : "Dispute Resolution for Restaurants - Just 5 minutes to sign up with Voosh, and you'll have money back in your pocket within a week!"
    },
    {
        "id" : 69,
        "img" : Blog60,
        "title" : "😱 Who's got time to reply to thousands of customer reviews manually? 😱",
        "publishedDate" : "19th April, 2024",
        "url" : "/blogs/who-got-time-to-reply-to-thousands-of-customer-reviews-manually",
        "desc" : "Voosh has already replied to 50,000+ reviews, saving over 5,000 hours of labor and boosting customer retention for restaurants doing business in third party marketplaces."
    },
    {
        "id" : 70,
        "img" : Blog61,
        "title" : "7 Ways to Enhance Customer Service on DoorDash",
        "publishedDate" : "23rd April, 2024",
        "url" : "/blogs/7-ways-to-enhance-customer-service-on-doordash",
        "desc" : "Enhance customer service on DoorDash with these 7 strategies. From encouraging feedback to offering promotions, learn how to stand out and drive success for your restaurant. Click here to read more!"
    },
    {
        "id" : 71,
        "img" : Blog62,
        "title" : "Effortlessly Manage Your Uber Eats Promotions",
        "publishedDate" : "23rd April, 2024",
        "url" : "/blogs/effortlessly-manage-your-uber-eats-promotions",
        "desc" : "Effortlessly manage your Uber Eats promotions with Voosh.ai. Optimize your promotions to attract more customers, increase sales, and enhance your restaurant's performance. Visit voosh.ai to learn more"
    },
    {
        "id" : 72,
        "img" : Blog63,
        "title" : "How to Optimize Your Restaurant Operations: Proven Techniques",
        "publishedDate" : "24th April, 2024",
        "url" : "/blogs/how-to-optimize-your-restaurant-operations-proven-techniques",
        "desc" : "Optimize your restaurant operations with proven techniques. Streamline workflows, manage employees effectively, and leverage technology to enhance customer experience, control costs, and maximize profits. Click to learn more!"
    },
    {
        "id" : 73,
        "img" : Blog64,
        "title" : "🔥 Voosh vs. the Rest? 🔥 Why Does Voosh Stand Out in the Crowded #RestaurantTech Market?",
        "publishedDate" : "24th April, 2024",
        "url" : "/blogs/voosh-vs-the-rest-why-does-voosh-stand-out-in-the-crowded-restaurant-tech-market",
        "desc" : "Discover how Voosh effortlessly handles disputed orders, promotions, customer interactions, financial reconciliation, reduces downtime, and more—all in one place!"
    },
    {
        "id" : 74,
        "img" : Blog65,
        "title" : "Maximize Revenue and Minimize Downtime on DoorDash with Voosh.ai",
        "publishedDate" : "30th April, 2024",
        "url" : "/blogs/maximize-revenue-and-minimize-downtime-on-doordash-with-voosh-ai",
        "desc" : "Maximize revenue & minimize downtime on DoorDash with Voosh.ai. Streamline your restaurant business with features like Dispute Manager, Promotion Manager, and Reviews & Ratings management. Sign up for a free."
    },
    {
        "id" : 75,
        "img" : Blog66,
        "title" : "Gain Valuable Insights to Boost Your DoorDash Performance with Voosh.ai's Analytics & Insights",
        "publishedDate" : "30th April, 2024",
        "url" : "/blogs/gain-valuable-insights-to-boost-your-doordash-performance-with-voosh-ai-analytics-insights",
        "desc" : "Boost your DoorDash performance with Voosh.ai's Analytics & Insights. Gain valuable data-driven insights to enhance your restaurant's efficiency, increase dispute win rates, and recover lost profits. Start your free trial!"
    },
    {
        "id" : 76,
        "img" : Blog67,
        "title" : "Improve Your GrubHub Reputation with Voosh.ai's Reviews & Ratings Management",
        "publishedDate" : "30th April, 2024",
        "url" : "/blogs/improve-your-grubhub-reputation-with-voosh-ai-reviews-ratings-management",
        "desc" : "Improve your GrubHub reputation with Voosh.ai's Reviews & Ratings Management. Boost your visibility and attract new customers with powerful features. Sign up for Voosh.ai services today!"
    },
    {
        "id" : 77,
        "img" : Blog68,
        "title" : "🚀 Running a multi-unit restaurant is incredibly challenging.",
        "publishedDate" : "04th May, 2024",
        "url" : "/blogs/running-a-multi-unit-restaurant-is-incredibly-challenging",
        "desc" : "At Voosh, we simplify and supercharge your third-party operations to ensure every transaction is as profitable as possible."
    },
    {
        "id" : 78,
        "img" : Blog69,
        "title" : "Improve Your Restaurant's Efficiency with Voosh.ai",
        "publishedDate" : "16th May, 2024",
        "url" : "/blogs/improve-your-restaurant-efficiency-with-voosh",
        "desc" : "Improve your restaurant's efficiency with Voosh.ai. Streamline operations, enhance customer experiences, and boost overall efficiency. Discover how embracing Voosh.ai can transform your restaurant into a well-oiled machine."
    },
    {
        "id" : 79,
        "img" : Blog70,
        "title" : "Unlock Valuable Insights for Your Restaurant's Growth on DoorDash with Voosh.ai",
        "publishedDate" : "16th May, 2024",
        "url" : "/blogs/unlock-valuable-insights-for-your-restaurant-growth-on-doordash-with-voosh",
        "desc" : "Unlock valuable insights for your restaurant's growth on DoorDash with Voosh.ai. Discover how Voosh.ai's tools can maximize potential, optimize menus, and drive revenue."
    },
    {
        "id" : 80,
        "img" : Blog71,
        "title" : "Drive Success on Uber Eats with Voosh.ai's Dispute Manager",
        "publishedDate" : "24th May, 2024",
        "url" : "/blogs/drive-success-on-uber-eats-with-voosh-ai-dispute-manager",
        "desc" : "Unlock success on Uber Eats with Voosh.ai's Dispute Manager. Revolutionize dispute resolution, enhance satisfaction, boost performance!"
    },
    {
        "id" : 81,
        "img" : Blog72,
        "title" : "Streamline Your Financial Processes on DoorDash with Voosh.ai's Finance & Reconciliation",
        "publishedDate" : "28th May, 2024",
        "url" : "/blogs/streamline-your-financial-processes-on-doordash-with-voosh-ai-finance-reconciliation",
        "desc" : "Optimize financial processes on DoorDash with Voosh.ai's Finance & Reconciliation. Streamline, reduce errors, and grow efficiently!"
    },
    {
        "id" : 82,
        "img" : Blog73,
        "title" : "Maximize Revenue on GrubHub with Voosh.ai's Downtime Controller",
        "publishedDate" : "29th May, 2024",
        "url" : "/blogs/maximize-revenue-on-grubhub-with-voosh-ai-downtime-controller",
        "desc" : "Maximize revenue on GrubHub with Voosh.ai's Downtime Controller. Drive profitability, stay competitive, and enhance customer satisfaction."
    },
    {
        "id" : 83,
        "img" : Blog74,
        "title" : "Increase Your DoorDash Sales with Voosh.ai's Promotion Manager",
        "publishedDate" : "29th May, 2024",
        "url" : "/blogs/increase-your-doordash-sales-with-voosh-ai-promotion-manager",
        "desc" : "Boost sales and attract customers with Voosh.ai's Promotion Manager. Discover successful marketing strategies with DoorDash promotions."
    },
    {
        "id" : 84,
        "img" : Blog75,
        "title" : "Increase Sales and ROI with AI-Driven Promotion Manager",
        "publishedDate" : "31st May, 2024",
        "url" : "/blogs/increase-sales-and-roi-with-ai-driven-promotion-manager",
        "desc" : "Boost sales and ROI with AI-driven promotion managers. Discover key features, benefits, and real-world success stories in marketing."
    },
    {
        "id" : 85,
        "img" : Blog76,
        "title" : "Drive Success with Voosh.ai and GrubHub",
        "publishedDate" : "07th June, 2024",
        "url" : "/blogs/drive-success-with-voosh-ai-and-grubhub",
        "desc" : "Discover how Voosh.ai's AI optimizes restaurant operations and GrubHub's delivery services boost efficiency and profitability. Learn more!"
    },
    {
        "id" : 86,
        "img" : Blog77,
        "title" : "Eliminate Downtime on DoorDash with Voosh.ai",
        "publishedDate" : "10th June, 2024",
        "url" : "/blogs/eliminate-downtime-on-doordash-with-voosh",
        "desc" : "Eliminate DoorDash downtime with Voosh.ai. Optimize performance, predict issues, and ensure seamless operations. Try Voosh.ai today!"
    },
    {
        "id" : 87,
        "img" : Blog78,
        "title" : "Dominate the Food Delivery Market with Voosh's Solutions for Restaurants",
        "publishedDate" : "14th June, 2024",
        "url" : "/blogs/dominate-the-food-delivery-market-with-voosh-solutions-for-restaurants",
        "desc" : "Dominate the food delivery market with Voosh's innovative solutions. Optimize operations, enhance customer satisfaction, and boost profits."
    },
    {
        "id" : 88,
        "img" : Blog79,
        "title" : "Everything You Need to Know About Doordash",
        "publishedDate" : "22nd June, 2024",
        "url" : "/blogs/everything-you-need-to-know-about-doordash",
        "desc" : "Discover how DoorDash revolutionizes food delivery with convenience, variety, and benefits for both consumers and restaurant partners."
    },
    {
        "id" : 89,
        "img" : Blog80,
        "title" : "Maximize Your Marketing ROI with Uber Eats Promotions",
        "publishedDate" : "22nd June, 2024",
        "url" : "/blogs/maximize-your-marketing-roi-with-uber-eats-promotions",
        "desc" : "Boost your business with Uber Eats promotions. Increase visibility, attract new customers, and drive sales with actionable tips."
    },
    {
        "id" : 90,
        "img" : Blog81,
        "title" : "The Benefits of Account Reconciliation Automation for Restaurants",
        "publishedDate" : "26th June, 2024",
        "url" : "/blogs/the-benefits-of-account-reconciliation-automation-for-restaurants",
        "desc" : "Discover how account reconciliation automation can save time, reduce errors, and enhance financial management for restaurants."
    },
    {
        "id" : 999,
        "img" : Blog82,
        "title" : "A Step-by-Step Guide to Disputing Order Errors on DoorDash and UberEats",
        "publishedDate" : "29th June, 2024",
        "url" : "/blogs/a-step-by-step-guide-to-disputing-order-errors-on-doordash-and-ubereats",
        "desc" : "Simplify your operations and maximize revenue recovery with Voosh's automated dispute management solution. Ideal for restaurant owners seeking to streamline error charge disputes and enhance operational efficiency."
    },
    {
        "id" : 91,
        "img" : Blog83,
        "title" : "How to Improve Your Restaurant's Online Reputation with Review Management",
        "publishedDate" : "29th June, 2024",
        "url" : "/blogs/how-to-improve-your-restaurants-online-reputation-with-review-management",
        "desc" : "Boost your restaurant's online reputation with effective review management. Learn strategies to attract and retain customers."
    },
    {
        "id" : 92,
        "img" : Blog84,
        "title" : "Maximize Your DoorDash Performance with Voosh.ai",
        "publishedDate" : "05th July, 2024",
        "url" : "/blogs/maximize-your-doordash-performance-with-voosh-ai",
        "desc" : "Boost your DoorDash performance with Voosh.ai! Optimize doordash, increase earnings, and enhance efficiency with advanced tools and real-time data."
    },
    {
        "id" : 93,
        "img" : Blog85,
        "title" : "Financial Reconciliation for Restaurants: Best Practices and Tools",
        "publishedDate" : "10th July, 2024",
        "url" : "/blogs/financial-reconciliation-for-restaurants-best-practices-and-tools",
        "desc" : "Explore best practices and tools for financial reconciliation in restaurants, optimizing processes and enabling informed decision-making for financial stability."
    },
    {
        "id" : 94,
        "img" : Blog86,
        "title" : "Streamline Restaurant Management with Voosh.ai's Solutions",
        "publishedDate" : "11th July, 2024",
        "url" : "/blogs/streamline-restaurant-management-with-voosh-ai-solutions",
        "desc" : "Discover how Voosh.ai revolutionizes restaurant management with cutting-edge technologies, AI-driven tools, and holistic solutions."
    },
    {
        "id" : 95,
        "img" : Blog87,
        "title" : "The Role of Automation in Resolving Disputes and Chargebacks for Restaurants",
        "publishedDate" : "11th July, 2024",
        "url" : "/blogs/the-role-of-automation-in-resolving-disputes-and-chargebacks-for-restaurants",
        "desc" : "Automated solutions streamline restaurant dispute and chargeback management, ensuring efficiency and profitability."
    },
    {
        "id" : 96,
        "img" : Blog88,
        "title" : "Improve Your DoorDash Reviews with Voosh.ai",
        "publishedDate" : "17th July, 2024",
        "url" : "/blogs/improve-your-doordash-reviews-with-voosh-ai",
        "desc" : "Enhance your DoorDash reviews with Voosh.ai's technology and insights. Revolutionize your restaurant's reputation and drive customer satisfaction."
    },
    {
        "id" : 97,
        "img" : Blog89,
        "title" : "Gourmet Talks with Voosh Ep. 1: Inside PLNT Burger's Success | Christopher Treloar | Priyam Saraswat",
        "publishedDate" : "17th July, 2024",
        "url" : "/blogs/gourmet-talks-with-voosh-ep-1",
        "desc" : "Join us for an exclusive conversation between Christopher Treloar, CEO of PLNT Burger, and Priyam Saraswat, CEO and co-founder of Voosh."
    },
    {
        "id" : 98,
        "img" : Blog90,
        "title" : "Resolve Disputes and Recover Lost Profits with Voosh.ai's Dispute Manager",
        "publishedDate" : "18th July, 2024",
        "url" : "/blogs/resolve-disputes-and-recover-lost-profits-with-voosh-dispute-manager",
        "desc" : "Effectively resolve disputes and recover lost profits with Voosh.ai's Dispute Manager. Empower your business with streamlined dispute management."
    },
    {
        "id" : 99,
        "img" : Blog91,
        "title" : "Voosh Announces Integration with SkipTheDishes, Enhancing Restaurant Management Capabilities Across Canada",
        "publishedDate" : "23rd July, 2024",
        "url" : "/blogs/voosh-announces-integration-with-skip-the-dishes-enhancing-restaurant-management-capabilities-across-canada",
        "desc" : "Voosh integrates SkipTheDishes to streamline Canadian restaurant operations with automated review management and dispute resolution. Learn more!"
    },
    {
        "id" : 100,
        "img" : Blog92,
        "title" : "Enhance Customer Experience with Voosh.ai's Downtime Controller",
        "publishedDate" : "24th July, 2024",
        "url" : "/blogs/enhance-customer-experience-with-voosh-ai-downtime-controller",
        "desc" : "Elevate customer experience with Voosh.ai's Downtime Controller. Minimize downtime, drive profitability, and optimize service for success!"
    },
    {
        "id" : 101,
        "img" : Blog93,
        "title" : "Manage Your Online Reputation Effectively with Voosh.ai's Reviews & Ratings",
        "publishedDate" : "31st July, 2024",
        "url" : "/blogs/manage-your-online-reputation-effectively-with-voosh-ai-reviews-and-ratings",
        "desc" : "Elevate your online reputation with Voosh.ai's Reviews & Ratings. Proactive engagement, AI responses, and analytics for digital success."
    },
    {
        "id" : 102,
        "img" : Blog94,
        "title" : "Boost Your DoorDash Campaigns with Voosh.ai",
        "publishedDate" : "01st Aug, 2024",
        "url" : "/blogs/boost-your-doordash-campaigns-with-voosh-ai",
        "desc" : "Enhance your DoorDash campaigns with Voosh.ai's AI technology. Tailor promotions, maximize reach, and stand out in the market!"
    },
    {
        "id" : 103,
        "img" : Blog95,
        "title" : "Increase Your Doordash Revenue with Voosh.ai",
        "publishedDate" : "08th Aug, 2024",
        "url" : "/blogs/increase-your-doordash-revenue-with-voosh-ai",
        "desc" : "Boost Doordash revenue with Voosh.ai's innovative solutions. Maximize efficiency, reduce costs, and increase profits!"
    },
    {
        "id" : 104,
        "img" : Blog96,
        "title" : "Maximize Profits for Fast Food Restaurants with Voosh.ai",
        "publishedDate" : "16th Aug, 2024",
        "url" : "/blogs/maximize-profits-for-fast-food-restaurants-with-voosh-ai",
        "desc" : "Transform fast food success with Voosh.ai's AI tools. Maximize profits, streamline operations, delight customers, and gain insights."
    },
    {
        "id" : 105,
        "img" : Blog97,
        "title" : "Maximize Your Restaurant's Visibility with GrubHub, DoorDash, and Uber Eats",
        "publishedDate" : "16th Aug, 2024",
        "url" : "/blogs/maximize-your-restaurants-visibility-with-grubhub-doordash-and-ubereats",
        "desc" : "Boost restaurant visibility & revenue with GrubHub, DoorDash, & Uber Eats. Stand out in the food industry with strategic partnerships."
    },
    {
        "id" : 106,
        "img" : Blog98,
        "title" : "Improving Invoice Dispute Management for Restaurants: Tips and Best Practices",
        "publishedDate" : "20th Aug, 2024",
        "url" : "/blogs/improving-invoice-dispute-management-for-restaurants-tips-and-best-practices",
        "desc" : "Optimize restaurant invoice dispute management with key strategies for prioritizing disputes, timely submissions, evidence provision, and fraud prevention."
    },
    {
        "id" : 107,
        "img" : Blog99,
        "title" : "Simplify Financial Reconciliation and Gain Immediate Insights with Voosh.ai's Finance & Reconciliation",
        "publishedDate" : "21st Aug, 2024",
        "url" : "/blogs/simplify-financial-reconciliation-and-gain-immediate-insights-with-voosh-ai-finance-reconciliation",
        "desc" : "Simplify financial reconciliation with Voosh.ai's solution. Gain insights, automate tasks, and optimize efficiency effortlessly!"
    },
    {
        "id" : 108,
        "img" : Blog100,
        "title" : "Dominate the Food Delivery Industry with GrubHub, DoorDash, and Uber Eats",
        "publishedDate" : "23rd Aug, 2024",
        "url" : "/blogs/dominate-the-food-delivery-industry-with-grubHub-doordash-and-ubereats",
        "desc" : "Dominate the food delivery industry with GrubHub, DoorDash, and Uber Eats. Discover their strategies for success in a competitive market."
    },
    {
        "id" : 109,
        "img" : Blog101,
        "title" : "Achieve Zero Downtime and Maximize Your Restaurant's Revenue with Voosh.ai's Downtime Controller",
        "publishedDate" : "28th Aug, 2024",
        "url" : "/blogs/achieve-zero-downtime-and-maximize-your-restaurant-revenue-with-voosh-ai-downtime-controller",
        "desc" : "Unlock zero downtime and revenue maximization with Voosh.ai's Downtime Controller. Revolutionize your approach to operational challenges!"
    },
    {
        "id" : 110,
        "img" : Blog102,
        "title" : "Boost Your Restaurant's Business on DoorDash with Voosh.ai",
        "publishedDate" : "30th Aug, 2024",
        "url" : "/blogs/boost-your-restaurants-business-on-doordash-with-voosh-ai",
        "desc" : "Transform your restaurant's DoorDash success with Voosh.ai. Optimize promotions, manage disputes, and leverage insights for growth."
    },
    {
        "id" : 111,
        "img" : Blog103,
        "title" : "Maximize Your Promotions Across Multiple Platforms with Voosh.ai's Promotion Manager",
        "publishedDate" : "05th Sept, 2024",
        "url" : "/blogs/maximize-your-promotions-across-multiple-platforms-with-voosh-ai-promotion-manager",
        "desc" : "Elevate your promotions with Voosh.ai's Promotion Manager. Tailored campaigns, targeted offers, and data-driven insights await!"
    },
    {
        "id" : 112,
        "img" : Blog104,
        "title" : "Increase Your DoorDash Sales with Voosh.ai",
        "publishedDate" : "05th Sept, 2024",
        "url" : "/blogs/increase-your-doordash-sales-with-voosh-ai",
        "desc" : "Supercharge your DoorDash sales with Voosh.ai's powerful and innovative tools, designed to drive growth and ensure success in the dynamic food delivery industry."
    },
    {
        "id" : 998,
        "img" : Blog998,
        "title" : "Voosh's Dispute Resolution & Financial Reconciliation Explained",
        "publishedDate" : "09th Sept, 2024",
        "url" : "/blogs/dispute-resolution-and-reconciliation",
        "desc" : "This article provides a comprehensive explanation of Voosh's Dispute Manager and Finance Reconciliation, detailing the steps to streamline and enhance your restaurant's financial management process for improved efficiency and accuracy."
    },
    {
        "id" : 113,
        "img" : Blog105,
        "title" : "Streamline Your DoorDash Finances with Voosh.ai",
        "publishedDate" : "12th Sept, 2024",
        "url" : "/blogs/streamline-your-doordash-finances-with-voosh-ai",
        "desc" : "Simplify DoorDash finances with Voosh.ai: track earnings, monitor expenses, and plan for success. Optimize your restaurants revenue with ease!"
    },
    {
        "id" : 114,
        "img" : Blog106,
        "title" : "Voosh Finance: Streamlining Financial Reconciliation for Restaurants on Third-Party Marketplaces",
        "publishedDate" : "24th Sept, 2024",
        "url" : "/blogs/voosh-finance-streamlining-financial-reconciliation-for-restaurants-on-third-party-marketplaces",
        "desc" : "Streamline restaurant financial reconciliation with Voosh Finance. Real-time insights, accurate reporting, and automated reconciliation for effortless management."
    },
    {
        "id" : 115,
        "img" : Blog107,
        "title" : "Enhance Brand Reputation: Effectively Manage Reviews on Third-Party Marketplaces",
        "publishedDate" : "24th Sept, 2024",
        "url" : "/blogs/enhance-brand-reputation-effectively-manage-reviews-on-third-party-marketplaces",
        "desc" : "Effectively manage reviews on third-party marketplaces to boost brand reputation and drive success with Voosh's innovative solutions."
    },
    {
        "id" : 996,
        "img" : Blog108,
        "title" : "Boost Your Restaurant's Sales with GrubHub, DoorDash, and Uber Eats",
        "publishedDate" : "25th Sept, 2024",
        "url" : "/blogs/boost-your-restaurants-sales-with-grubhub-doordash-and-uber-eats",
        "desc" : "Maximize restaurant sales with GrubHub, DoorDash & Uber Eats. Discover success strategies with Voosh.ai for enhanced efficiency."
    },
    {
        "id" : 997,
        "img" : Blog109,
        "title" : "7 Proven Strategies for Restaurant Promotions on Delivery Platforms",
        "publishedDate" : "29th Sept, 2024",
        "url" : "/blogs/7-proven-strategies-for-restaurant-promotions-on-delivery-platforms",
        "desc" : "Discover 7 proven strategies for effective promotions on food delivery platforms, boosting visibility and driving sales."
    },
    {
        "id" : 118,
        "img" : Blog110,
        "title" : "Top Marketplace Management Services for Restaurants",
        "publishedDate" : "01st Oct, 2024",
        "url" : "/blogs/top-marketplace-management-services-for-restaurants",
        "desc" : "Explore how Voosh transforms restaurant marketplace management with cutting-edge tools. Thrive in a data-driven landscape with Voosh!"
    },
    {
        "id" : 995,
        "img" : Blog111,
        "title" : "How Restaurants Can Resolve Error Charges on Food Delivery Apps",
        "publishedDate" : "08th Oct, 2024",
        "url" : "/blogs/how-restaurants-can-resolve-error-charges-on-food-delivery-apps",
        "desc" : "Explore practical ways for restaurants to resolve error charges on popular food delivery apps and protect their revenue."
    },
    {
        "id" : 994,
        "img" : Blog112,
        "title" : "How to Increase Online Food Orders for Your Restaurant",
        "publishedDate" : "08th Oct, 2024",
        "url" : "/blogs/how-to-increase-online-food-orders-for-your-restaurant",
        "desc" : "Discover proven strategies to increase online food orders for your restaurant, including exclusive deals, optimizing menus, leveraging reviews, and automating disputes for revenue recovery."
    }
]
const blogArrays = [...BlogDataJson].sort((a, b) => b.id - a.id);

export const BlogCards = () => {
    const [searchItem, setSearchItem] = useState('')
    const [filteredBlogs, setFilteredBlogs] = useState(blogArrays)
    const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
    }
    const resetForm = () => {
        setSearchItem('')
    }
    useEffect(() => {
        const filteredItems = blogArrays.filter((blog) =>
        blog.title.toLowerCase().includes(searchItem.toLowerCase())
        );
        setFilteredBlogs(filteredItems)
    }, [searchItem]);
   
    

    return (
        <>
        <div className="s1-container v-blogs">
          <div className="f-width m-auto text-center">
            <div className="row ss-hero-data">
              <h1 className="hero-primary-text ss-h">Blogs</h1>
              <form className="form">
                <button disabled>
                    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </button>
                <input className="input" placeholder="Search Blogs" required="" type="text" value={searchItem} onChange={handleInputChange} />
                <button className="reset" type="reset" onClick={() => resetForm()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className='ss-section2 container-fluid'>
        <div className='f-width m-auto'>
            <Tabs defaultActiveKey="latest" transition={true} id="anim-tab" className="ss-tabs">
                <Tab eventKey="latest" title="Latest">
                    <div className='row'>
                        {filteredBlogs.length >= 1 ? filteredBlogs.map(item => (
                            <div key={item.id} className='col-lg-4 col-md-6 col-sm-12'>
                                <Card className='blog-card'>
                                    <Card.Img variant="top" src={item.img} alt='Voosh Blogs thumbnail'/>
                                    <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <p className='t3'>{item.desc}</p>
                                    <p className='t2 m-0'>Posted on : {item.publishedDate}</p>
                                    <Link to={item.url}>
                                        <div className='btn btn-outline-primary btn-with-arrow mt-3'><font className="pe-2">Read this story</font>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                                                <path className='stroke-blue' d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                    </Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        )): <p>No results.</p> }
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>
        </>
    )
  }


const getRandomArrays = (data, numArrays) => {
    const shuffledData = [...data].sort(() => 0.5 - Math.random()); // Shuffle the data
    return shuffledData.slice(0, numArrays); // Select the specified number of arrays
  };

export const GetSimilarBlogComponent = () => {
    const [randomArrays, setRandomArrays] = useState([]);
  
    // Generate four random arrays on initial render
    useState(() => {
      BlogDataJson.pop()
      const arrays = getRandomArrays(BlogDataJson, 4);
      setRandomArrays(arrays);
    }, []);
  
    return (
      <>
        {/* <ul>
            {randomArrays.map(item => (
              <li key={item.id}>{item.title}</li>
            ))}
        </ul> */}
        <div className='catch-up-blog'>
            <p className='rtss1 mb-0'><strong>Catch up on other editions
                <svg className='svg-icon ms-1' style={{width: "1.25em", height: "1.25em",verticalAlign: "middle",fill: "#f36513",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M986.584615 602.584615a23.630769 23.630769 0 0 1-39.384615 15.753847L840.861538 512 596.676923 720.738462a66.953846 66.953846 0 0 1-96.492308 0l-147.692307-167.384616L90.584615 783.753846a21.661538 21.661538 0 0 1-33.476923 0l-11.815384-11.815384a21.661538 21.661538 0 0 1 0-31.507693l259.938461-352.492307a66.953846 66.953846 0 0 1 96.492308 0l147.692308 147.692307 149.661538-173.292307-96.492308-88.615385a25.6 25.6 0 0 1 15.753847-41.353846h322.953846a47.261538 47.261538 0 0 1 43.323077 49.230769z"  /></svg>    
            </strong></p>
            <div className="s2-dm-bdr" style={{backgroundColor:"#f36513"}}></div>
            {randomArrays.map(item => (
                <Link key={item.id} to={item.url}>
                    <div className='row rounded py-2 mb-2'>
                        <div className='col-9'>
                            <p className='rtss2 mt-0'><strong>{item.title}</strong></p>
                            <p className='s-t2 text-black'>Posted on : {item.publishedDate}</p>
                        </div>
                        <div className='col-3 text-center'>
                            <img src={item.img} className='shadow-sm' alt='catch up blogs thumbnail' />
                        </div>
                    </div>
                </Link>
            ))}
            <div className='text-center'>
                <Link to={"/blogs"}>
                    <div className='btn btn-outline-primary btn-with-arrow mt-4'><font className="pe-2">See all editions</font>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                            <path className='stroke-blue' d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </Link>
            </div>
        </div>
      </>
    );
};