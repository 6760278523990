import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../src/assets/css/main.css";
import Navbar from "../src/components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import "./firebase";
import SStory1 from "./pages/SucessStories/SStory1";
import SStory2 from "./pages/SucessStories/SStory2";
import SStory3 from "./pages/SucessStories/SStory3";
import AboutUs from "./view/AboutUs";
import AnalyticsInsights from "./view/AnalyticsInsights";
import DisputeManager from "./view/DisputeManager";
import Finance from "./view/Finance";
import Landingpage from "./view/Landingpage";
import Productsview from "./view/Productsview";
import ReviewsRating from "./view/ReviewsRating";
import SucessStory from "./view/SucessStory";
import Press from "./view/Press";
import TnC from "./components/TnC";
import "./utils/integrations";
import PromotionManager from "./view/PromotionManager";
import UserGuide from "./view/UserGuide";
import Blogs from "./view/Blogs";
// import Blog15112023 from "./pages/blogs/Blog15112023";
import Blog1 from "./pages/blogs/Blog1";
import Blog21112023 from "./pages/blogs/Blog21112023";
import Blog28112023 from "./pages/blogs/Blog28112023";
import Blog30112023 from "./pages/blogs/Blog30112023";
import Blog05122023 from "./pages/blogs/Blog05122023";
import Blog06122023 from "./pages/blogs/Blog06122023";
import Blog08122023 from "./pages/blogs/Blog08122023";
import PR1 from "./pages/blogs/PR1";
import PR2 from "./pages/blogs/PR2";
import PR3 from "./pages/blogs/PR3";
import Blog11122023 from "./pages/blogs/Blog11122023";
import Blog12122023 from "./pages/blogs/Blog12122023";
import Blog13122023 from "./pages/blogs/Blog13122023";
import Blog28122023 from "./pages/blogs/Blog28122023";
import PrivacyPolicy from "./view/PrivacyPolicy";
import SStory4 from "./pages/SucessStories/SStory4";
import Blog03012024 from "./pages/blogs/Blog03012024";
import Blog04012024 from "./pages/blogs/Blog04012024";
import Blog08012024 from "./pages/blogs/Blog08012024";
import Blog09012024 from "./pages/blogs/Blog09012024";
import Blog10012024 from "./pages/blogs/Blog10012024";
import Blog11012024 from "./pages/blogs/Blog11012024";
import Blog18012024 from "./pages/blogs/Blog18012024";
import { Blog16, Blog17, Blog18 } from "./pages/blogs/Blog19012024";
import SStory5 from "./pages/SucessStories/SStory5";
import { Blog19, Blog20, Blog21, Blog22, Blog23, Blog24 } from "./pages/blogs/Blog28012024";
import WhatIsVoosh from "./view/WhatIsVoosh";
import { Blog25, Blog26, Blog27, Blog28, Blog29, Blog30, Blog31 } from "./pages/blogs/Blog08022024";
import DowntimeController from "./view/DowntimeController";
import { Blog32, Blog33, Blog34, Blog35, Blog36 } from "./pages/blogs/Blog22022024";
import { Blog37, PR4, PR5 } from "./pages/blogs/PR28022024";
import { Blog38, Blog39, Blog40, Blog41, Blog42 } from "./pages/blogs/Blog09032024";
import SStory04 from "./pages/SucessStories/SStory04";
import SStory05 from "./pages/SucessStories/SStory05";
import { SStory06, SStory07, SStory08, SStory09, SStory10 } from "./pages/SucessStories/SStory25mar";
import { Blog43b, Blog44b, Blog45b, Blog46b } from "./pages/blogs/Blog25032024";
import BrixHoldings from "./view/BrixHoldings";
import GrouchoDeli from "./view/GrouchoDeli";
import { Blog47b, Blog48b, Blog49b, Blog50b, Blog51b, Blog52b, Blog53b, Blog54b, Blog55b, Blog56b, Blog57b, Blog58b, Blog59b, Blog60b, Blog61b, Blog62b, Blog63b, Blog64b, Blog65b, Blog66b, Blog67b } from "./pages/blogs/BlogApr2024";
import CookieConsent from "./components/CookieConsent";
import GhostKitchenBrands from "./view/GhostKitchenBrands";
import { Blog68b, Blog69b, Blog70b, Blog71b, Blog72b, Blog73b, Blog74b, Blog75b } from "./pages/blogs/BlogMay2024";
import Rocknrollsushi from "./view/Rocknrollsushi";
import { SStory11, SStory12, SStory13, SStory14, SStory15, SStory16, SStory17 } from "./pages/SucessStories/SStoryJun2024";
import { Blog76b, Blog77b, Blog78b, Blog79b, Blog80b, Blog81b, Blog83b } from "./pages/blogs/BlogJun2024";
import Article1 from "./view/Article1";
import { Blog84b, Blog85b, Blog86b, Blog87b, Blog88b, Blog89b, Blog90b, Blog91b, Blog92b, Blog93b } from "./pages/blogs/BlogJul2024";
import { Blog100b, Blog101b, Blog102b, Blog94b, Blog95b, Blog96b, Blog97b, Blog98b, Blog99b } from "./pages/blogs/BlogAug2024";
import { Blog103b, Blog104b, Blog105b, Blog106b, Blog107b, Blog108b, Blog109b } from "./pages/blogs/BlogSept2024";
import Article2 from "./view/Article2";
import { Blog110b, Blog111b, Blog112b } from "./pages/blogs/BlogOct2024";

function App() {

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/product-features" element={<Productsview />} />
          <Route path="/dispute-manager" element={<DisputeManager />} />
          <Route path="/promotion-manager" element={<PromotionManager />} />
          <Route path="/downtime-controller" element={<DowntimeController />} />
          <Route path="/review-rating" element={<ReviewsRating />} />
          <Route path="/analytics-insights" element={<AnalyticsInsights />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/success-stories" element={<SucessStory />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/success-stories/farm-burger" element={<SStory1 />} />
          <Route path="/success-stories/profitable-promotions-a- new-york-chain-recipe-for-19.92x-ROI" element={<SStory3 />} />
          <Route path="/success-stories/plnt-burger" element={<SStory2 />} />
          <Route path="/success-stories/&pizza+Voosh-a-match-made-in-pizza-heaven" element={<SStory4 />} />
          <Route path="/success-stories/breaking-news-voosh-achieves-significant-financial-recovery-for-dig" element={<SStory04 />} />
          <Route path="/success-stories/voosh-saves-original-chop-shop-a-whopping-$27,000" element={<SStory05 />} />
          <Route path="/success-stories/voosh-among-top-3-startups-at-murtec-2024" element={<SStory06 />} />
          <Route path="/success-stories/voosh-ai-powered-2-million-plus-orders-hitting-$57M-in-sales" element={<SStory07 />} />
          <Route path="/success-stories/essential-dispute-resolution-strategies-for-restaurants" element={<SStory08 />} />
          <Route path="/success-stories/voosh-saves-farm-burger-a-whopping-$7,000" element={<SStory09 />} />
          <Route path="/success-stories/voosh-saves-grouchos-deli-a-whopping-$13,000" element={<SStory10 />} />
          <Route path="/success-stories/here-how-a-northeast-restaurant-chain-recovered-72000-in-7-months" element={<SStory11 />} />
          <Route path="/success-stories/how-a-health-focused-eatery-recovered-35000-dollars-in-8-months" element={<SStory12 />} />
          <Route path="/success-stories/voosh-has-powered-an-incredible-100-million-dollar-in-sales-with-5-million-plus-orders" element={<SStory13 />} />
          <Route path="/success-stories/how-a-top-bay-area-restaurant-chain-saved-over-500k-dollars-in-a-year" element={<SStory14 />} />
          <Route path="/success-stories/christopher-treloar-ceo-of-plnt-burger-shares-how-voosh-transformed-their-operations-and-saved-them-thousands" element={<SStory15 />} />
          <Route path="/success-stories/why-would-you-not-automate-it" element={<SStory16 />} />
          <Route path="/success-stories/mcdonald-franchisee-saves-$40k-in-90-days-with-voosh-automation" element={<SStory17 />} />
          <Route path="/press" element={<Press />} />
          <Route path="/rockemsockemsocial" element={<TnC />} />
          <Route path="/userguide" element={<UserGuide />} />
          <Route path="/blogs/a-step-by-step-guide-to-disputing-order-errors-on-doordash-and-ubereats" element={<Article1 />} />
          <Route path="/blogs/dispute-resolution-and-reconciliation" element={<Article2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/a-comprehensive-guide-to-marketplace-management-for-restaurants" element={<Blog1 />} />
          <Route path="/blogs/mastering-dispute-management-on-doordash-ubereats-and-grubhub" element={<Blog21112023 />} />
          <Route path="/blogs/streamlining-financial-reconciliation-for-restaurants-using-third-party-delivery-platforms" element={<Blog28112023 />} />
          <Route path="/blogs/top-10-tips-for-managing-your-restaurant-on-third-party-delivery-platforms" element={<Blog30112023 />} />
          <Route path="/blogs/how-to-effectively-manage-promotions-for-your-restaurant-on-third-party-platforms" element={<Blog05122023 />} />
          <Route path="/blogs/maximize-your-restaurants-growth-on-third-party-delivery-platforms-doordash-ubereats-grubhub-tips-and-tricks" element={<Blog06122023 />} />
          <Route path="/blogs/financial-reconciliation-for-restaurants-simplifying-processes-and-boosting-profits" element={<Blog08122023 />} />
          <Route path="/blogs/voosh-introduces-ezcater-integration-elevating-financial-management-for-restaurants" element={<PR1 />} />
          <Route path="/blogs/voosh-enhances-dispute-manager-with-groundbreaking-doordash-drive-integration" element={<PR2 />} />
          <Route path="/blogs/voosh-announces-major-upgrade-to-review-manager-feature-now-includes-google-and-yelp-integration" element={<PR3 />} />
          <Route path="/blogs/voosh-welcomes-ezcater-integration-making-financial-management-a-piece-of-cake" element={<Blog11122023 />} />
          <Route path="/blogs/voosh-dispute-manager-meets-doordash-drive" element={<Blog12122023 />} />
          <Route path="/blogs/voosh-review-manager-now-even-better-hello-google-and-yelp-integration" element={<Blog13122023 />} />
          <Route path="/blogs/mastering-reviews-and-ratings-boost-your-restaurants-reputation-on-doordash-ubereats-and-grubhub" element={<Blog28122023 />} />
          <Route path="/blogs/ghost-kitchens-vs-multi-unit-restaurants-maximizing-profits-with-vooshs-restaurant-marketplace-intelligence-platform" element={<Blog03012024 />} />
          <Route path="/blogs/maximizing-profits-on-doordash-tips-and-tricks-for-restaurant-operators" element={<Blog04012024 />} />
          <Route path="/blogs/grubhub-growth-hacks-boost-your-restaurants-performance-on-the-platform" element={<Blog08012024 />} />
          <Route path="/blogs/the-secret-to-ubereats-success-how-restaurants-can-optimize-operations" element={<Blog09012024 />} />
          <Route path="/blogs/how-voosh-can-help-restaurants-reduce-error-charges-and-win-disputes" element={<Blog10012024 />} />
          <Route path="/blogs/master-the-art-of-dispute-resolution-for-third-party-delivery-services-tips-and-tricks-for-doordash-grubhub-and-ubereats" element={<Blog11012024 />} />
          <Route path="/blogs/top-5-reasons-to-choose-voosh-for-your-restaurants-third-party-marketplace-management" element={<Blog18012024 />} />
          <Route path="/blogs/optimizing-reviews-and-ratings-for-your-restaurant-on-delivery-platforms" element={<Blog16 />} />
          <Route path="/blogs/the-role-of-quick-service-restaurants-in-the-era-of-third-party-delivery-platforms" element={<Blog17 />} />
          <Route path="/blogs/streamlining-finance-and-reconciliation-for-restaurants-on-delivery-platforms" element={<Blog18 />} />
          <Route path="/success-stories/did-you-know-a-global-food-giant-is-projected-to-save-$340,000-in-just-30-days" element={<SStory5 />} />
          <Route path="/blogs/10-examples-of-ai-powered-customer-support-in-food-delivery" element={<Blog19 />} />
          <Route path="/blogs/5-ways-to-maximize-your-pizza-delivery-profits" element={<Blog20 />} />
          <Route path="/blogs/doordash-vs-ubereats-vs-grubhub-which-platform-is-best-for-your-restaurant" element={<Blog21 />} />
          <Route path="/what-is-voosh" element={<WhatIsVoosh /> } />
          <Route path="/brixholdings" element={<BrixHoldings /> } />
          <Route path="/grouchos" element={<GrouchoDeli />} />
          <Route path="/ghostkitchenbrands" element={<GhostKitchenBrands />} />
          <Route path="/rocknrollsushi" element={<Rocknrollsushi />} />
          <Route path="/blogs/why-yelp-matters-leveraging-online-reviews-to-boost-your-restaurants-reputation" element={<Blog22 />} />
          <Route path="/blogs/top-5-voosh-features-every-restaurant-needs-to-succeed" element={<Blog23 />} />
          <Route path="/blogs/increasing-dispute-win-rates-and-reducing-error-charges-for-restaurants-with-voosh" element={<Blog24 />} />
          <Route path="/blogs/optimizing-your-restaurant-performance-on-uber-eats-top-tips-and-strategies" element={<Blog25 />} />
          <Route path="/blogs/boost-your-chinese-cuisine-orders-with-these-tips-and-maximize-profits" element={<Blog26 />} />
          <Route path="/blogs/leveraging-voosh-ai-for-restaurant-success-on-door-dash-uber-eats-and-grub-hub" element={<Blog27 />} />
          <Route path="/blogs/why-voosh-is-a-game-changer-for-restaurants-navigating-third-party-delivery-platforms" element={<Blog28 />} />
          <Route path="/blogs/a-comprehensive-guide-to-financial-statement-reconciliation-for-restaurants-using-voosh" element={<Blog29 />} />
          <Route path="/blogs/maximize-restaurant-profits-with-voosh-a-comprehensive-solution-for-third-party-delivery-management" element={<Blog30 />} />
          <Route path="/blogs/unlocking-papa-johns-success-6-lessons-for-growing-your-restaurant" element={<Blog31 />} />
          <Route path="/blogs/fox-news-top-4-restaurant-industry-trends-to-watch" element={<Blog32 />} />
          <Route path="/blogs/stay-informed-with-breaking-news-in-the-restaurant-industry" element={<Blog33 />} />
          <Route path="/blogs/jack-in-the-boxs-success-leveraging-third-party-delivery-platforms" element={<Blog34 />} />
          <Route path="/blogs/how-starbucks-excels-in-delivery-platform-management" element={<Blog35 />} />
          <Route path="/blogs/voosh-launches-third-party-marketplace-downtime-controller" element={<PR4 />} />
          <Route path="/blogs/voosh-adds-promotions-creation-tool-to-its-third-party-marketplace-promotions-manager" element={<PR5 />} />
          <Route path="/blogs/how-to-succeed-on-doordash-essential-tips-for-restaurants" element={<Blog36 />} />
          <Route path="/blogs/unlocking-the-power-of-google-for-your-business" element={<Blog37 />} />
          <Route path="/blogs/boost-your-door-dash-performance-with-voosh-ai" element={<Blog38 />} />
          <Route path="/blogs/maximize-your-restaurants-performance-on-grubhub" element={<Blog39 />} />
          <Route path="/blogs/unlock-the-power-of-ai-for-efficient-restaurant-management" element={<Blog40 />} />
          <Route path="/blogs/maximize-your-profits-with-voosh-ai-promotions-management" element={<Blog41 />} />
          <Route path="/blogs/increasing-profitability-with-profitable-promotions-a-success-story-from-a-new-york-based-culinary-chain" element={<Blog42 />} />
          <Route path="/blogs/maximizing-profits-on-uber-eats-top-strategies-for-restaurants" element={<Blog43b />} />
          <Route path="/blogs/food-delivery-near-me-5-strategies-for-choosing-the-best-platform-for-your-restaurant" element={<Blog44b />} />
          <Route path="/blogs/take-control-of-your-revenue-with-dispute-management" element={<Blog45b />} />
          <Route path="/blogs/increase-roi-with-voosh-ai-promotion-manager-for-grubhub" element={<Blog46b />} />
          <Route path="/blogs/wages-have-definitely-doubled-since-2008-says-shawn-p-walchef-of-cali-bbq" element={<Blog47b />} />
          <Route path="/blogs/top-5-ways-to-use-doordash-promo-codes-for-boosting-your-restaurant-sales" element={<Blog48b />} />
          <Route path="/blogs/maximize-your-revenue-on-doordash-proven-strategies-for-restaurants" element={<Blog49b />} />
          <Route path="/blogs/drive-more-orders-and-increase-revenue-with-effective-uber-eats-strategies" element={<Blog50b />} />
          <Route path="/blogs/john-olivers-last-week-tonight-reveals-a-shocking-truth-behind-the-convenience-of-food-delivery-apps" element={<Blog51b />} />
          <Route path="/blogs/boost-your-delivery-business-with-automated-intelligence-solutions" element={<Blog52b />} />
          <Route path="/blogs/efficiently-manage-disputes-on-uber-eats" element={<Blog53b />} />
          <Route path="/blogs/drive-exceptional-campaign-results-on-doordash-with-voosh-ai-promotion-manager" element={<Blog54b />} />
          <Route path="/blogs/enhance-your-restaurant-reputation-on-doordash-with-voosh-reviews-ratings-management" element={<Blog55b />} />
          <Route path="/blogs/most-brands-spend-10-15-percent-of-sales-on-promotions-without-any-clear-return" element={<Blog56b />} />
          <Route path="/blogs/is-your-promotional-strategy-really-tailored-to-your-market" element={<Blog57b />} />
          <Route path="/blogs/did-you-know-that-over-$500-million-is-lost-annually-in-disputed-restaurant-orders" element={<Blog58b />} />
          <Route path="/blogs/samuel-vp-at-tgi-fridays-asked-at-murtec-how-quickly-can-you-see-returns-with-voosh" element={<Blog59b />} />
          <Route path="/blogs/who-got-time-to-reply-to-thousands-of-customer-reviews-manually" element={<Blog60b />} />
          <Route path="/blogs/7-ways-to-enhance-customer-service-on-doordash" element={<Blog61b />} />
          <Route path="/blogs/effortlessly-manage-your-uber-eats-promotions" element={<Blog62b />} />
          <Route path="/blogs/how-to-optimize-your-restaurant-operations-proven-techniques" element={<Blog63b />} />
          <Route path="/blogs/voosh-vs-the-rest-why-does-voosh-stand-out-in-the-crowded-restaurant-tech-market" element={<Blog64b />} />
          <Route path="/blogs/maximize-revenue-and-minimize-downtime-on-doordash-with-voosh-ai" element={<Blog65b />} />
          <Route path="/blogs/gain-valuable-insights-to-boost-your-doordash-performance-with-voosh-ai-analytics-insights" element={<Blog66b />} />
          <Route path="/blogs/improve-your-grubhub-reputation-with-voosh-ai-reviews-ratings-management" element={<Blog67b />} />
          <Route path="/blogs/running-a-multi-unit-restaurant-is-incredibly-challenging" element={<Blog68b />} />
          <Route path="/blogs/improve-your-restaurant-efficiency-with-voosh" element={<Blog69b />} />
          <Route path="/blogs/unlock-valuable-insights-for-your-restaurant-growth-on-doordash-with-voosh" element={<Blog70b />} />
          <Route path="/blogs/drive-success-on-uber-eats-with-voosh-ai-dispute-manager" element={<Blog71b />} />
          <Route path="/blogs/streamline-your-financial-processes-on-doordash-with-voosh-ai-finance-reconciliation" element={<Blog72b />} />
          <Route path="/blogs/maximize-revenue-on-grubhub-with-voosh-ai-downtime-controller" element={<Blog73b />} />
          <Route path="/blogs/increase-your-doordash-sales-with-voosh-ai-promotion-manager" element={<Blog74b />} />
          <Route path="/blogs/increase-sales-and-roi-with-ai-driven-promotion-manager" element={<Blog75b />} />
          <Route path="/blogs/drive-success-with-voosh-ai-and-grubhub" element={<Blog76b />} />
          <Route path="/blogs/eliminate-downtime-on-doordash-with-voosh" element={<Blog77b />} />
          <Route path="/blogs/dominate-the-food-delivery-market-with-voosh-solutions-for-restaurants" element={<Blog78b />} />
          <Route path="/blogs/everything-you-need-to-know-about-doordash" element={<Blog79b />} />
          <Route path="/blogs/maximize-your-marketing-roi-with-uber-eats-promotions" element={<Blog80b />} />
          <Route path="/blogs/the-benefits-of-account-reconciliation-automation-for-restaurants" element={<Blog81b />} />
          <Route path="/blogs/how-to-improve-your-restaurants-online-reputation-with-review-management" element={<Blog83b />} />
          <Route path="/blogs/maximize-your-doordash-performance-with-voosh-ai" element={<Blog84b />} />
          <Route path="/blogs/financial-reconciliation-for-restaurants-best-practices-and-tools" element={<Blog85b />} />
          <Route path="/blogs/streamline-restaurant-management-with-voosh-ai-solutions" element={<Blog86b />} />
          <Route path="/blogs/the-role-of-automation-in-resolving-disputes-and-chargebacks-for-restaurants" element={<Blog87b />} />
          <Route path="/blogs/improve-your-doordash-reviews-with-voosh-ai" element={<Blog88b />} />
          <Route path="/blogs/gourmet-talks-with-voosh-ep-1" element={<Blog89b />} />
          <Route path="/blogs/resolve-disputes-and-recover-lost-profits-with-voosh-dispute-manager" element={<Blog90b />} />
          <Route path="/blogs/voosh-announces-integration-with-skip-the-dishes-enhancing-restaurant-management-capabilities-across-canada" element={<Blog91b />} />
          <Route path="/blogs/enhance-customer-experience-with-voosh-ai-downtime-controller" element={<Blog92b />} />
          <Route path="/blogs/manage-your-online-reputation-effectively-with-voosh-ai-reviews-and-ratings" element={<Blog93b />} />
          <Route path="/blogs/boost-your-doordash-campaigns-with-voosh-ai" element={<Blog94b />} />
          <Route path="/blogs/increase-your-doordash-revenue-with-voosh-ai" element={<Blog95b />} />
          <Route path="/blogs/maximize-profits-for-fast-food-restaurants-with-voosh-ai" element={<Blog96b />} />
          <Route path="/blogs/maximize-your-restaurants-visibility-with-grubhub-doordash-and-ubereats" element={<Blog97b />} />
          <Route path="/blogs/improving-invoice-dispute-management-for-restaurants-tips-and-best-practices" element={<Blog98b />} />
          <Route path="/blogs/simplify-financial-reconciliation-and-gain-immediate-insights-with-voosh-ai-finance-reconciliation" element={<Blog99b />} />
          <Route path="/blogs/dominate-the-food-delivery-industry-with-grubHub-doordash-and-ubereats" element={<Blog100b />} />
          <Route path="/blogs/achieve-zero-downtime-and-maximize-your-restaurant-revenue-with-voosh-ai-downtime-controller" element={<Blog101b />} />
          <Route path="/blogs/boost-your-restaurants-business-on-doordash-with-voosh-ai" element={<Blog102b />} />
          <Route path="/blogs/maximize-your-promotions-across-multiple-platforms-with-voosh-ai-promotion-manager" element={<Blog103b />} />
          <Route path="/blogs/increase-your-doordash-sales-with-voosh-ai" element={<Blog104b />} />
          <Route path="/blogs/streamline-your-doordash-finances-with-voosh-ai" element={<Blog105b />} />
          <Route path="/blogs/voosh-finance-streamlining-financial-reconciliation-for-restaurants-on-third-party-marketplaces" element={<Blog106b />} />
          <Route path="/blogs/enhance-brand-reputation-effectively-manage-reviews-on-third-party-marketplaces" element={<Blog107b />} />
          <Route path="/blogs/boost-your-restaurants-sales-with-grubhub-doordash-and-uber-eats" element={<Blog108b />} />
          <Route path="/blogs/7-proven-strategies-for-restaurant-promotions-on-delivery-platforms" element={<Blog109b />} />
          <Route path="/blogs/top-marketplace-management-services-for-restaurants" element={<Blog110b />} />
          <Route path="/blogs/how-restaurants-can-resolve-error-charges-on-food-delivery-apps" element={<Blog111b />} />
          <Route path="/blogs/how-to-increase-online-food-orders-for-your-restaurant" element={<Blog112b />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </BrowserRouter>
    </>
  );
}

export default App;
